.di-chat-message {
  
  &__system {
    width:100%;
  }

  &-separator {
    margin-top:1rem;
    margin-bottom: 1rem;
  }
}

.cs-message-list__scroll-wrapper>.cs-message-group.di-message-group__system {
  max-width: none;
  .cs-message-group__content {
    width: 100%;
    .cs-message__content-wrapper {
      width: 100%;
      .cs-message__content {
        //background-color: #ffffe1;
        background-color: #fff;
        text-align: center;
      }
    }
  }
}

.di-typing-indicator {
  
  &.cs-typing-indicator {
    background-color: var(--cs-main-container-bg-color);  
  }

  .cs-typing-indicator__dot {
    background-color: var(--cs-typing-indicator-dot-bg-color);
  }
  
  &--hidden {
    .cs-typing-indicator__indicator {
      display: none
    }
  }
  
  &__content {
    display: inline-block;
    width: calc(100% - 4px);
    overflow: hidden;
    text-overflow: ellipsis;
    // Miejsce na outline
    margin-top: 2px;
    margin-left: 2px;
    margin-bottom: 2px;
    padding-left: 2px;
    padding-right: 2px;
    color: var(--cs-typing-indicator-dot-bg-color);
  }
}