.help {
  
  color: var(--help-text-color);
  
  &__h1 {
    color: var(--help-h1-color);
  }

  &__h2 {
    color: var(--help-h2-color);
  }
  
  &__link {
    color: var(--help-link-color);
  }
  
}