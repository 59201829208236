@import "~@chatscope/chat-ui-kit-styles/themes/default/variables";

.wc-header {
  &__btn {
    color: var(--di-wc-header-btn-color) !important;
    border-color: var(--di-wc-header-btn-border-color) !important;
    font-weight: bold;
    &:focus,
    &:hover {
      color: var(--di-a11y-btn-color);
    }
    &:focus-visible {
      outline: var(--di-focus-visible-outline);
    }
  }
  
  &__user-name {
    font-size: 1em;
  }
}