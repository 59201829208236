@import "~@chatscope/chat-ui-kit-styles/themes/default/variables";

.a11y-toolbox {
  &__theme {
    
  }
  
  &__font-size {
    color: (--cs-color-text);
  }
  
  &__app-size {
    
  }
  
  &__separator {
    display: block;
    margin: 0 0.5rem;
    transform: translateY(-2px);
    font-size: 1.35rem;
    color: var(--di-a11y-btn-color);
  }
  
  &__font-reset {
    display: block;
    font-size: 1.35rem;
    font-weight: bold;
    transform: translateY(-1px);
    color: var(--di-a11y-btn-color);
  }

  &__btn-wrapper {
    font-size: $main-container-font-size; // Ustawiam na sztywno tak samo jak main container, żeby nie zwiększało przy zwiększeniu nadrzędnego  
  }
  .a11y-btn {
    
    &:not(:first-child) {
      margin-left: 0.2rem;  
    }
    color: var(--di-a11y-btn-color);
    padding: 0.2rem 0.4rem;
    &--active {
      outline: var(--di-a11y-btn-outline);
    }

    &:focus,
    &:hover {
      color: var(--di-a11y-btn-color);
    }
    &:focus-visible {
      outline: var(--di-focus-visible-outline);
    }

  }
  
}