body, html, #root {
  width: 100%;
  height:100%;
  margin:0;
  padding:0;
  box-sizing: border-box;
}

body {
  overscroll-behavior: none;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

}
#root {
  position:relative;
  overflow: hidden;
}